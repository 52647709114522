import { Injectable, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { Router, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SwalService implements OnDestroy {
  private destroy$ = new Subject<void>(); // Used to manage unsubscription

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {
    // Subscribe to router events with takeUntil for proper cleanup
    this.router.events
      .pipe(takeUntil(this.destroy$)) // Automatically unsubscribe when destroy$ emits
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.closeSwal();
          this.closeAllDialogs();
        }
      });
  }

  // Global function to close SweetAlert popups
  closeSwal(): void {
    Swal.close();
  }

  // Close all MatDialog instances
  closeAllDialogs(): void {
    //TODO   the page change  and the dialog close
    // this.dialog.closeAll();
  }
  // OnDestroy lifecycle hook to clean up the subscription
  ngOnDestroy(): void {
    this.destroy$.next(); // Emits a value to signal unsubscription
    this.destroy$.complete(); // Completes the subject to avoid memory leaks
  }
}
