import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import {
  Component,
  Inject,
  OnInit,
  VERSION,
  ViewChildren
} from '@angular/core';

import { ImageCropperComponent } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vex-image-crap-resize',
  standalone: true,
  imports: [SharedModule, ImageCropperComponent],
  templateUrl: './image-crap-resize.component.html',
  styleUrls: ['./image-crap-resize.component.scss']
})
export class ImageCrapResizeComponent {
  @ViewChildren(ImageCropperComponent) imageCroppers: any;

  cropWidth: any = '';
  cropQuality = 60;
  cropHeight: any = '';
  cropType = 'jpeg';
  file: any;
  storageRef: any;
  newThumbnail: any[];
  url: string;
  croppedImages: any[] = [];
  cFiles: any = [];
  keepSpectRatio = false;
  backgroundColor: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ImageCrapResizeComponent>,
    private toastr: ToastrService
  ) {
    this.cFiles = [];
    this.croppedImages = [];
    this.cFiles = data.cFiles;
    this.croppedImages = data.croppedImages;
  }
  ngOnInit(): void {}

  recrop() {
    this.imageCroppers.toArray().forEach((el: any, index: number) => {
      this.imageCropped(index, el.crop());
    });
  }

  getspectRatio() {
    if (this.cropWidth && this.cropHeight) {
      this.keepSpectRatio = true;
      // tslint:disable-next-line: radix
      return parseInt(this.cropWidth) / parseInt(this.cropHeight);
    } else {
      return 1;
    }
  }

  getFormat(ext: any): any {
    if (this.cropType === '*') {
      return ext + '';
    } else {
      return this.cropType;
    }
  }

  getFilenameExtension(file: any) {
    let ex = file.name.split('.').pop();
    if (ex === 'jpg') ex = 'jpeg';
    return ex;
  }

  getBackgroundColor(item: any) {
    let color: any = '#fff';
    if (this.cropType === 'png') {
      color = null;
    } else {
      if (this.cropType === '*' && item.type.indexOf('png') !== -1) {
        color = null;
      }
    }
    return color;
  }

  imageCropped(index: any, event: any) {
    if (event.width > 1920) {
      this.cropWidth = '1920';
      this.cropHeight = Math.round((event.height / event.width) * 1920) + '';
    }

    // Process the cropped image (circular)
    const timg = {
      blob: event.blob || event.base64,
      src: event.objectUrl,
      width: event.width,
      height: event.height,
      owidth: !this.croppedImages[index].owidth
        ? event.width
        : this.croppedImages[index].owidth,
      oheight: !this.croppedImages[index].oheight
        ? event.height
        : this.croppedImages[index].oheight,
      size: this.getFileSize(event.blob),
      osize: this.croppedImages[index].osize,
      name: this.croppedImages[index].name,
      type: this.croppedImages[index].type,
      key: '',
      // Optionally store the cropper position (though it will be for the rectangle area)
      cropperPosition: {
        x1: event.cropperPosition.x1,
        y1: event.cropperPosition.y1,
        x2: event.cropperPosition.x2,
        y2: event.cropperPosition.y2
      }
    };

    this.croppedImages[index] = timg;
  }

  getFileSize(croppedBlob: any) {
    // Get file size in bytes
    const fileSizeInBytes = croppedBlob.size;

    const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2);

    const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2);

    // console.log(`File size: ${fileSizeInBytes} bytes`);
    // console.log(`File size: ${fileSizeInKB} KB`);
    // console.log(`File size: ${fileSizeInMB} MB`);

    return fileSizeInBytes;
  }

  // paste handler
  async paste() {
    let blob;
    try {
      const clipboardItems = await (navigator.clipboard as any).read();
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          blob = await clipboardItem.getType(type);
          if (type.startsWith('image')) {
            const img = document.createElement('img');
            img.src = URL.createObjectURL(blob);

            const file = new File([blob], 'image', {
              type: blob.type
            });
            this.cropWidth = '';
            this.cropHeight = '';
            this.keepSpectRatio = false;
            this.cFiles = [];
            this.croppedImages = [];
            this.cFiles.push({
              ext: this.getFilenameExtension(file),
              file: file,
              type: file.type
            });
            this.croppedImages.push({
              osize: (file.size / 1024).toFixed(0) + 'KB',
              name: file.name,
              type: this.cropType ? 'image/' + this.cropType : file.type
            });
          }
        }
      }
      return blob;
    } catch (error) {
      console.log('paste error', error);
    }
  }

  onSubmit() {
    this.dialogRef.close({ ...this.croppedImages[0] });
  }
}
