import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { environment } from 'src/assets/environments/environment.prod';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class CartService {
  private refreshList = new BehaviorSubject<boolean>(false);
  cartItemCount: number = 0;
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}
  getListObservable() {
    return this.refreshList.asObservable();
  }

  triggerListRefresh() {
    this.refreshList.next(true);
  }

  getCart(id: any, programConfigId: any, quotId?: string): Observable<any> {
    let url: string;

    // if (quotId) {
    //   url = `cart/${id}/${programConfigId}?quotationId=${quotId}`;
    // } else {
    url = `cart/${id}/${programConfigId}`;
    // }

    return this.http.get(url, httpOptions).pipe(
      map((resp: any) => {
        let retData: any;
        if (resp && resp.data) {
          let rows = resp.data;
          let quotationDetails: any[] = [];
          let cartItems = rows.cartItems;
          // Grouping the cartItems by their `quotationId`
          cartItems.forEach((i: any) => {
            if (Array.isArray(i.programConfig.attachment)) {
              let imageUrl =
                environment.mediaUrl +
                i.programConfig.attachment[0]?.dir +
                '/' +
                i.programConfig.attachment[0]?.fileName;
              i.imageUrl = imageUrl;
            }
            i.cartId = rows.id;

            // Find if there's already a group in `quotationDetails` for this `quotationId`
            let quotationGroup = quotationDetails.find(
              (q) => q.quotationNumber === i.quotationId
            );

            if (quotationGroup) {
              // If the group exists, push this `cartItem` into its `cartItems` array
              quotationGroup.cartItems.push(i);
            } else if (i.quotationId) {
              // If the group doesn't exist, create a new group and push the `cartItem`
              quotationDetails.push({
                quotationNumber: i.quotationId,
                ...i.quotationDetails,
                cartItems: [i]
              });
            }
          });
          // Now, you have an array `quotationDetails` with items grouped by `quotationId`
          // After grouping, remove the cart items that have already been pushed to quotationDetails
          cartItems = cartItems.filter((i: any) => {
            // Return false if this item has already been grouped in quotationDetails, so it gets removed
            return !quotationDetails.some((q) => q.cartItems.includes(i));
          });

          let temp: any = {
            ...rows,
            cartItems: cartItems,
            id: rows.id,
            quotationDetails,
            cartItemCount: rows?.cartItems.length,
            checkoutCartItems: rows?.cartItems
          };
          this.cartItemCount = rows?.cartItems.length;
          retData = temp;
          return retData;
        }
        this.cartItemCount = 0;
        return of({
          cartItems: [],
          quotationDetails: [],
          checkoutCartItems: [],
          AgreementItem: []
        });
      })
    );
  }

  createCart(data: any): Observable<any> {
    return this.http.post('cart', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  updateCartItem(id: any, data: any): Observable<any> {
    return this.http.put('cart/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  deleteCartItem(cartId: any, cartItemId: any): Observable<any> {
    return this.http
      .delete(`cart/${cartId}/cartItem/` + cartItemId, httpOptions)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }

  deleteCart(cartID: any, programConfigID: any): Observable<any> {
    return this.http
      .delete(`cart/clearCart/${cartID}/${programConfigID}`, httpOptions)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }

  createSample(data: any): Observable<any> {
    let datas: any = {
      cartData: { ...data }
    };
    return this.http.post('checkout/sampleFlow', datas, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  createBrand(data: any): Observable<any> {
    return this.http.post('brand/multiple', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  getCartList(id: any, programConfigId: any, quotId?: string): Observable<any> {
    let url: string;

    // if (quotId) {
    //   url = `cart/${id}/${programConfigId}?quotationId=${quotId}`;
    // } else {
    url = `cart/${id}/${programConfigId}`;
    // }

    return this.http.get(url, httpOptions).pipe(
      map((resp: any) => {
        let retData: any;
        let temp: any = [];

        if (resp && resp.data) {
          let rows = resp.data;
          console.log(resp.data);
          temp = rows.map((res: any) => {
            res.cartItems.forEach((i: any) => {
              if (Array.isArray(i.programConfig.attachment)) {
                let imageUrl =
                  environment.mediaUrl +
                  i.programConfig.attachment[0]?.dir +
                  '/' +
                  i.programConfig.attachment[0]?.fileName;
                i.imageUrl = imageUrl;
              }
            });
            return res;
          });
          console.log(temp);
          this.cartItemCount = temp?.length;
          retData = temp;
          return retData;
        }
        return of([]);
      }),
      catchError((err) => {
        return of([]);
      })
    );
  }
}
