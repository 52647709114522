<vex-base-layout *ngIf="config$ | async as config">
  <vex-progress-bar></vex-progress-bar>

  <!-- <vex-search></vex-search> -->

  <mat-sidenav-container class="vex-layout-sidenav-container">
    <mat-sidenav
      (closedStart)="onSidenavClosed()"
      [disableClose]="!!(sidenavDisableClose$ | async)"
      [fixedInViewport]="!!(sidenavFixedInViewport$ | async)"
      [mode]="(sidenavMode$ | async) ?? 'side'"
      [opened]="!!(sidenavOpen$ | async)">
      <vex-sidenav [collapsed]="!!(sidenavCollapsed$ | async)"></vex-sidenav>
    </mat-sidenav>

    <mat-sidenav
      (closedStart)="onCartClosed()"
      [opened]="!!(cartOpen$ | async)"
      class="vex-layout-cart"
      mode="over"
      position="end"
      [autoFocus]="false"
      style="width: 25vw !important">
      <ng-container *ngIf="cartOpen$">
        <vex-cart></vex-cart>
      </ng-container>
    </mat-sidenav>
    <mat-sidenav-content class="vex-layout-sidenav-content">
      <vex-toolbar
        [class.dark]="config.layout === 'vertical'"
        class="vex-toolbar"></vex-toolbar>

      <main class="vex-layout-content">
        <app-loader [loading]="isLoading$ | async"></app-loader>
        <router-outlet></router-outlet>
      </main>

      <vex-footer class="!static"></vex-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</vex-base-layout>
