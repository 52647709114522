import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { Router, RouterLink } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  distinctUntilChanged,
  filter,
  firstValueFrom
} from 'rxjs';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { CommonModule } from '@angular/common';
import { CartManager } from './cart-manager';
import { CartService } from './cart.service';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { UserAuthenticationService } from 'src/app/core/navigation/user-authantication.service';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AddPoNumberComponent } from 'src/app/pages/quotation/add-po-number/add-po-number.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QuotationService } from 'src/app/pages/quotation/quotation.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'vex-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  standalone: true,
  imports: [
    MatDividerModule,
    MatListModule,
    RouterLink,
    MatRippleModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    CartItemsComponent,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class cartComponent implements OnInit {
  cartData: any = { cartItems: [], quotationDetails: [] };
  subTotal: number;
  processingFee: number;
  taxes: number;
  total: number;

  userInfo: any;
  programConfigId: any;
  programDetails: any;
  totalPrice: any;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  isLoading: boolean = false;
  AgreementPONumber: string;
  constructor(
    private router: Router,
    private readonly layoutService: VexLayoutService,
    private cartManager: CartManager,
    private cartService: CartService,
    private appSettingsService: AppSettingsService,
    public userCheck: UserAuthenticationService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private dialog: MatDialog,
    private quotationService: QuotationService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.userInfo = this.appSettingsService.userData().userDetails;
    await this.onInit();
  }

  async onInit() {
    try {
      let programInfo = await firstValueFrom(this.dataService.getUserData());
      this.dataService
        .getProgramCartObservable()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async (res) => {
          if (res) {
            let programInfo = await firstValueFrom(
              this.dataService.getUserData()
            );
            this.licenseAgreementCart = false;
            if (programInfo && programInfo?.programConfigId !== null) {
              this.getCartData(programInfo, programInfo?.programConfigId);
              this.cdr.detectChanges();
            }
          }
        });
      if (programInfo?.programConfigId) {
        this.programConfigId = programInfo?.programConfigId;
      }
    } catch (err) {
      console.error(err);
    }
  }

  get cartDataGetter() {
    return this.cartData;
  }
  licenseAgreementCart: boolean = false;
  licenseAgreementCartTotalPriceDue: number;
  async getCartData(programInfo: any, programConfigId: number) {
    let params: any = {
      userID: this.userInfo.id
    };
    if (!programConfigId) return;
    this.programDetails = programInfo;
    let agreement: any;
    // Check if the programInfo object meets the conditions for adding a license agreement to the cart
    if (
      !programInfo?.isPaid &&
      programInfo?.isFinalized &&
      !programInfo?.isOrdered
    ) {
      this.licenseAgreementCart = true;
      if (programInfo.isQuotation) {
        this.licenseAgreementCartTotalPriceDue =
          programInfo.quotationDetails.amount;
      } else {
        this.licenseAgreementCartTotalPriceDue = programInfo.licenseFee;
      }
      if (programInfo.isQuotation) {
        if (this.AgreementPONumber) {
          this.AgreementPONumber = this.AgreementPONumber;
        } else {
          this.AgreementPONumber = programInfo?.quotationDetails?.PONumber;
        }
      }

      let data = {
        id: 1,
        userID: this.userInfo.id,
        companyID: programInfo.companyId,
        totalPrice: programInfo.licenseFee,
        tax: 0,
        processingFee: 0,
        programConfigID: programInfo.programConfigId,
        quotationId: programInfo.quotationId,
        quotationDetails: programInfo.quotationDetails,
        AgreementItem: [
          {
            class: 'LicenseAgreement',
            name: `${programInfo.programName} Annual License`,
            price: programInfo.licenseFee,
            quotationDetails: programInfo.quotationDetails,
            quotationId: programInfo.quotationId,
            isQuotation: programInfo.isQuotation
          }
        ]
      };
      this.cartData = data;
      this.totalPrice = this.cartData.totalPrice;
      this.cartManager.cartData = data;
      agreement = data;
      // this.dataService.triggerProgramCartCountRefresh();
      // return;
    } else {
      let data = {
        AgreementItem: []
      };
      this.cartData = data;
    }
    console.log(this.cartData);
    try {
      this.isLoading = true;
      let cartResp = await firstValueFrom(
        this.cartService.getCart(this.userInfo.id, programConfigId)
      );
      this.totalPrice = 0;
      cartResp.cartItems.forEach((item: any) => {
        if (!item.quotationId) {
          this.totalPrice += item.price;
        }
      });
      if (this.licenseAgreementCart) {
        this.cartService.cartItemCount += 1;
      } else {
        this.cartService.cartItemCount = this.cartService.cartItemCount;
      }
      this.selectedCartItems = [];
      this.cartData = { ...this.cartData, ...cartResp };
      this.cartManager.cartData = cartResp;

      // this.totalPrice = this.cartData?.totalPrice;
      this.cdr.detectChanges();
      this.dataService.triggerProgramCartCountRefresh();
      this.isLoading = false;
    } catch (err: any) {
      if (this.licenseAgreementCart) {
        this.cartService.cartItemCount = 1;
      } else {
        this.cartService.cartItemCount = 0;
      }
      console.log(this.cartData);
      this.cartData = { ...this.cartData, cartItems: [], quotationDetails: [] };
      this.dataService.triggerProgramCartCountRefresh();
      this.isLoading = false;
    }
  }

  async deleteCart(item: any) {
    // this.cartManager.deleteCart(item);
    this.appSettingsService
      .confirm('Delete', 'Are you sure you want to delete?')
      .then(async (res) => {
        if (res) {
          let itemId = item.id;
          try {
            let cartResp = await firstValueFrom(
              this.cartService.deleteCartItem(this.cartData.id, itemId)
            );
            this.ngOnInit();
            this.cartService.triggerListRefresh();
          } catch (err: any) {
            throw new Error(err);
          }
        }
      });
  }

  checkout() {
    const queryParams: any = {
      programConfigId: this.programDetails.id
    };

    // Add cartItems only if selectedCartItems has items
    if (this.selectedCartItems.length > 0) {
      queryParams.cartItems = this.selectedCartItems.map((i) => i.id).join(',');
    }

    this.router.navigate(['/checkout'], {
      queryParams: queryParams
    });
    this.layoutService.closeCart();
  }

  onAgreementCheckout() {
    if (this.programDetails.isQuotation) {
      this.router.navigate(['/home', 1], {
        queryParams: {
          programConfigId: this.programDetails.id,
          quotationNumber: this.programDetails.quotationId
        }
      });
    } else {
      this.router.navigate(['/home', 1], {
        queryParams: {
          programConfigId: this.programDetails.id
        }
      });
    }

    this.layoutService.closeCart();
  }
  cancel(): void {
    this.layoutService.closeCart();
  }

  async onClearCart(): Promise<any> {
    try {
      this.appSettingsService
        .confirm('Delete', 'Are you sure you want to delete?')
        .then(async (res) => {
          if (res) {
            let cartResp = await firstValueFrom(
              this.cartService.deleteCart(
                this.cartData.id,
                this.programConfigId
              )
            );
            this.dataService.triggerProgramCartRefresh();
          }
        });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  checkoutQuot(quoatId: number): void {
    const queryParams: any = {
      programConfigId: this.programDetails.id,
      quotationNumber: quoatId
    };

    // Add cartItems only if selectedCartItems has items
    // if (this.selectedCartItems.length > 0) {
    //   queryParams.cartItems = this.selectedCartItems
    //     .map((i) => i.id)
    //     .join(',');
    // }

    this.router.navigate(['/checkout'], {
      queryParams: queryParams
    });
    this.layoutService.closeCart();
  }
  selectedCartItems: any[] = [];

  itemChecked(ev: { item: any; isChecked: boolean }): void {
    this.totalPrice = 0;

    if (ev.isChecked) {
      // If the item is checked, push it to selectedCartItems
      this.selectedCartItems.push(ev.item);
      this.selectedCartItems.forEach((i) => {
        this.totalPrice += i.price;
      });
    } else {
      // If the item is unchecked, remove it from selectedCartItems
      this.selectedCartItems = this.selectedCartItems.filter(
        (i) => i.id !== ev.item.id
      );
      if (this.selectedCartItems.length > 0) {
        this.selectedCartItems.forEach((i) => {
          this.totalPrice += i.price;
        });
      } else {
        this.cartData.cartItems.forEach((item: any) => {
          if (!item.quotationId) {
            this.totalPrice += item.price;
          }
        });
      }
    }
  }

  onOpenPanel(cartItem: any): boolean {
    // Check if any of the items in cartItem.cartItems array exist in selectedCartItems
    const isItemSelected = cartItem.cartItems.some((cartSubItem: any) =>
      this.selectedCartItems.some(
        (selectedItem) => selectedItem.id === cartSubItem.id
      )
    );
    return isItemSelected;
  }

  onShowSelectItemsCount(): boolean {
    return this.selectedCartItems.length > 0;
  }

  hasCartItemsOrQuotationDetails(): boolean {
    return (
      (this.cartData &&
        this.cartData.cartItems &&
        this.cartData.cartItems.length > 0) ||
      (this.cartData &&
        this.cartData.quotationDetails &&
        this.cartData.quotationDetails.length > 0)
    );
  }

  hasCartItemsDetails(): boolean {
    return (
      this.cartData &&
      this.cartData.cartItems &&
      this.cartData.cartItems.length > 0
    );
  }
  hasCartItemsAgreementDetails(): boolean {
    return this.licenseAgreementCart;
  }

  onIsLoading(ev: boolean): void {
    this.isLoading = ev;
  }

  onAddQuotationNo(ev: any): void {
    let id = ev.id;
    let dialogRef: MatDialogRef<AddPoNumberComponent>;
    dialogRef = this.dialog.open(AddPoNumberComponent, {
      data: ev,
      autoFocus: false,
      width: '40%'
    });

    dialogRef.afterClosed().subscribe(async (resp: any) => {
      if (resp) {
        this.isLoading = true;
        let data: any = {
          PONumber: resp.PONumber
        };
        try {
          await firstValueFrom(this.quotationService.updateQuotation(id, data));
          this.AgreementPONumber = data.PONumber;
          this.toastr.success('PO number updated successfully!');
          await this.onInit();
        } catch (err) {
          this.appSettingsService.handleError(err);
        }
      }
    });
  }
}
