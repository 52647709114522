<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ "" }}
  </h2>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<mat-divider class="text-border"></mat-divider>
<mat-dialog-content>
  <div
    class="flex flex-col justify-between gap-4"
    *ngFor="let item of cFiles; let i = index">
    <div class="cropper-container">
      <image-cropper
        [imageQuality]="cropQuality"
        [imageFile]="item.file"
        [format]="getFormat(item.ext)"
        [onlyScaleDown]="true"
        [resizeToWidth]="cropWidth"
        [resizeToHeight]="cropHeight"
        [backgroundColor]="getBackgroundColor(item)"
        [maintainAspectRatio]="true"
        [aspectRatio]="1"
        [roundCropper]="true"
        (imageCropped)="imageCropped(i, $event)"
        class="ngx-ic-square"></image-cropper>

      <!-- Backdrop with circular hole for the cropper -->
      <!-- <div class="crop-backdrop">
        <div class="crop-circle"></div>
      </div> -->
    </div>
    <!-- Image preview -->
    <!-- <div class="col-xs-6">
      <img
        style="max-width: 100%"
        src="{{ croppedImages[i]?.src }}"
        class="preview-img" />
    </div> -->
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">Cancel</button>
  <button color="primary" mat-flat-button type="submit" (click)="onSubmit()">
    Upload
  </button>
</mat-dialog-actions>
