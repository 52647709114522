import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { firstValueFrom } from 'rxjs';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';

@Component({
  selector: 'vex-cart-items',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartItemsComponent {
  @Input() item: any;
  @Input() licenseAgreementCart: boolean = false;
  @Input() programDetails: any;
  @Input() showCheckBox: boolean = true;
  @Input() showDelete: boolean = true;
  @Output() itemChecked = new EventEmitter<any>(); // Emit the checked item to the parent component
  @Output() isLoading = new EventEmitter<any>();
  @Input() PONumber: string = '-';
  @Input() showPO: boolean = false;
  isChecked = false; // Track the checkbox state

  // Function to handle checkbox changes
  onCheckChange() {
    this.itemChecked.emit({ item: this.item, isChecked: this.isChecked }); // Emit the item and whether it's checked
  }

  private appSettingsService = inject(AppSettingsService);
  private cartService = inject(CartService);
  private router = inject(Router);
  private layoutService = inject(VexLayoutService);
  private dataService = inject(DataService);
  async deleteCart(item: any) {
    this.appSettingsService
      .confirm('Delete', 'Are you sure you want to delete?')
      .then(async (res) => {
        if (res) {
          let itemId = item.id;
          this.isLoading.emit(true);
          try {
            let cartResp = await firstValueFrom(
              this.cartService.deleteCartItem(item.cartId, itemId)
            );
            this.dataService.triggerProgramCartRefresh();
          } catch (err: any) {
            throw new Error(err);
          }
        }
      });
  }

  checkout() {
    if (this.licenseAgreementCart) {
      if (this.programDetails.quotationId) {
        this.router.navigate(['/home', 1], {
          queryParams: {
            programConfigId: this.programDetails.id,
            quotationNumber: this.programDetails.id
          }
        });
      } else {
        this.router.navigate(['/home', 1], {
          queryParams: {
            programConfigId: this.programDetails.id
          }
        });
      }

      this.layoutService.closeCart();
    } else {
      this.router.navigate(['/checkout'], {
        queryParams: {
          programConfigId: this.programDetails.id
        }
      });
      this.layoutService.closeCart();
    }
  }
}
