import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { parseResponse } from 'src/app/core/helpers';
import { StorageMap } from '@ngx-pwa/local-storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { PasswordChange } from './auth-interface';
import { environment } from 'src/assets/environments/environment.prod';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any;
  isLoggedIn = false;
  roleAs: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorage: StorageMap,
    private toastr: ToastrService,
    private appSettingsService: AppSettingsService
  ) {}

  /*
   *  getLocalStorageUser function is used to get local user profile data.
   */
  getLocalStorageUser() {
    this.userData = this.localStorage.get('ng-token');
    if (this.userData) {
      this.isLoggedIn = true;
      return true;
    } else {
      this.isLoggedIn = false;
      return false;
    }
  }

  /*
   *  getRole function is used to get local user role.
   */
  getRole() {
    this.roleAs = localStorage.getItem('role');
    return this.roleAs;
  }

  /*
   * loginUser fuction used to login
   */
  loginUser(params: any) {
    return this.http.post('auth/login', params).pipe(
      map((resp) => {
        return parseResponse(resp);
      }),
      mergeMap((resp: any) => {
        if (!resp.data.accessToken) {
          return of(resp);
        }
        let userAttachment = resp.data?.userDetails?.attachments?.find(
          (i: any) => i.class == 'MyProfile'
        );
        let logo =
          environment.mediaUrl +
          userAttachment?.dir +
          '/' +
          userAttachment?.fileName;
        let data = { ...resp.data, userImgUrl: logo };
        return this.appSettingsService
          .setUserToken(resp.data.userDetails, resp.data.accessToken)
          .pipe(
            map(() => {
              this.setLocalUserProfile(data);
              return resp;
            })
          );
      })
    );
  }

  /**
   * registerLicensee
   */
  public registerLicensee(params: any) {
    return this.http.post('company/register', params, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  /*
   * loginUser fuction used to login
   */
  loginStaff(params: any) {
    return this.http.post('staffs/login', params).pipe(
      map((resp) => {
        return parseResponse(resp);
      }),
      mergeMap((resp) => {
        if (!resp.token) {
          return of(resp);
        }
        // else {
        //   this.setLocalUserProfile(resp);
        // }
        return this.appSettingsService
          .setUserToken(resp.data.role_id, resp.token)
          .pipe(
            map(() => {
              this.setLocalUserProfile(resp.data);
              return resp;
            })
          );
      })
    );
  }
  /*
   * loginUser fuction used to login
   */
  logoutStaff() {
    return this.http.get('staffs/logout?is_register_open=true').pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  /*
   * createNewPassword fuction used to new password
   */
  newPass(params: any, isStaff: any) {
    return this.http
      .post(isStaff ? 'staffs/password' : 'password', params)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }

  /*
   * changePassword fuction used to change password
   */
  changePassword(params: any) {
    return this.http.post('users/change_password', params).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  /*
   * forgotPassword fuction check email validation
   */
  forgotPass(params: any, staff: any) {
    return this.http
      .post(staff ? 'staffs/forgot_password' : 'users/forgot_password', params)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }

  /*
   * changePass
   */
  changePass(params: any, staff: any) {
    return this.http
      .post(staff ? 'staffs/change_password' : 'users/change_password', params)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }
  /*
   * OTPVerify fuction check email validation
   */
  otpVerify(params: any, staff: any) {
    return this.http
      .post(staff ? 'staffs/verify_otp' : 'users/verify_otp', params)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }

  public resetPassword(params: any, isStaff: any) {
    return this.http
      .post(isStaff ? 'staffs/reset_password' : 'users/reset_password', params)
      .pipe(
        map((resp) => {
          return parseResponse(resp);
        })
      );
  }
  /*
   * logOut function is used to sign out
   */
  logOut(id?: any) {
    this.localStorage.delete('ng-token');

    //todo add required keys
    const localStorageKeys = [
      'userProfile',
      'role',
      'cart',
      'fq-program-license',
      'fq-program',
      'fq-program-sampleType',
      'fq-program-sample',
      'fq-program-license-agreement-process',
      'fq-program-sample-test-result',
      'fq-program-sample-test',
      'fq-program-sample-taf',
      'fq-program-brand-annual'
    ];

    // Define an array of keys to be removed from localStorage
    localStorageKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
    // Iterate through each key in the localStorageKeys array
    // and remove the corresponding item from localStorage

    this.appSettingsService.setUserSettings(null);
    this.isLoggedIn = false;

    // if (id && id === 5) {
    //   this.logoutStaff().subscribe((r) => {
    //     this.toastr.success('Successfully logged out!');
    //     location.reload();
    //   });
    // } else {
    //   this.localStorage.clear().subscribe(
    //     () => {},
    //     () => {},
    //     () => {
    //       this.toastr.success('Successfully logged out!');
    //       // this.router.navigate(['/session/login']);
    //       location.reload();
    //     }
    //   );
    // }
    this.toastr.success('Successfully logged out!');
  }

  /*
   * setLocalUserProfile function is used to set local user profile data.
   */
  setLocalUserProfile(value: any) {
    localStorage.setItem('userProfile', JSON.stringify(value));
    this.appSettingsService.setUserSettings(value);
    this.isLoggedIn = true;
  }

  //Change Password

  onChangePassword(data: PasswordChange) {
    return this.http.post('user/password/change', data).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  onUpdateProfile(data: any) {
    return this.http.put('user/myProfile', data).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  onForgotPassword(data: any) {
    return this.http.post('user/password/forgot', data).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  onResetPassword(data: any) {
    return this.http.post('user/password/reset', data).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  onNewPassword(data: any) {
    return this.http.post('user/password/set', data).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  onVerifyEmail(data: any) {
    return this.http.put('company/verify', data).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  //handle error messages
  handleError(err: any) {
    if (err.message && typeof err.message === 'object') {
      const message = err.message;
      for (const key in message) {
        if (message.hasOwnProperty(key)) {
          this.toastr.error(message[key]);
        }
      }
    } else if (err.message) {
      this.toastr.error(err.message);
    }
  }
  onResendEmailVerifiCation(data: any) {
    return this.http.post('company/resend/verification', data).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  // Method to delete profile image
  deleteProfileImage(userId: string): Observable<any> {
    const url = `user/${userId}/image`;
    return this.http.delete(url).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
}
