import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';

import { FormControl } from '@angular/forms';
import { DialogComponent } from '../dialog/dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'vex-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatIconModule, CommonModule, MatButtonModule]
})
export class ConfirmDialogComponent implements OnInit {
  public title: string = 'Please confirm';
  public message: string = 'Are you sure you want to Delete ?';
  public btnCancelText: string = 'Cancel';
  public btnOkText: string = 'Delete';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title || 'Please confirm';
      this.message = this.data.message || 'Are you sure you want to Delete ?';
      this.btnCancelText = this.data.btnCancelText || 'Cancel';
      this.btnOkText = this.data.btnOkText || 'Delete';
    }
  }

  close(data: any) {
    this.dialogRef.close(data);
  }
  onConfirm() {
    this.dialogRef.close({
      submit: true
    });
  }
}
