import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NetworkService } from './network-service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'vex-network-warning',
  template: `
    <!-- <div *ngIf="isOffline" class="network-warning-overly-message">
      <button
        class="text-white absolute top-0 right-0 font-bold"
        mat-dialog-close
        mat-icon-button
        type="button"
        (click)="isOffline = false">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
      <span>Network connection lost</span>
    </div> -->

    <div *ngIf="isOffline" class="network-warning">
      <div class="flex flex-row items-center gap-4 font-semibold">
        <mat-icon svgIcon="mat:wifi_off"></mat-icon> Network connection lost
      </div>
    </div>

    <div *ngIf="!isOffline && showMessage" class="network-success">
      <div class="flex flex-row items-center gap-4 font-semibold">
        <mat-icon svgIcon="mat:check"></mat-icon> Network connection back
      </div>
    </div>
  `,
  styles: [
    `
      .network-warning-overly-message {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
      .network-warning {
        color: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        background: var(--vex-sidenav-background);
        transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
        padding: 10px 0;
        z-index: 999;
        display: flex;
        justify-content: center;
      }
      .network-success {
        color: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        background: var(--cqa-background-primary);
        transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
        padding: 10px 0;
        z-index: 999;
        display: flex;
        justify-content: center;
      }
    `
  ],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class NetworkWarningComponent implements OnInit, OnDestroy {
  isOffline = !window.navigator.onLine; // Initialize based on current connection status
  showMessage = false;
  private subscription: Subscription;
  private wasOffline = !window.navigator.onLine; // Track the initial network state

  constructor(private networkService: NetworkService) {}

  ngOnInit() {
    // Subscribe to network status changes
    this.subscription = this.networkService.connected$.subscribe(
      (connected) => {
        this.isOffline = !connected;

        if (connected && this.wasOffline) {
          // Only show the message if previously offline
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
          }, 3000);
        }

        // Update wasOffline to track the network state for the next emission
        this.wasOffline = !connected;
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
