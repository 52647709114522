import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormat]',
  exportAs: 'appCurrencyFormat',
  standalone: true
})
export class CurrencyFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    this.formatCurrency(event.target.value);
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    this.formatCurrency(event.target.value, true);
  }

  private formatNumber(n: string): string {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private formatCurrency(inputValue: string, blur?: boolean): void {
    let input_val = inputValue;
    if (input_val === '') {
      return;
    }

    const original_len = input_val.length;
    const caret_pos = this.el.nativeElement.selectionStart;

    if (input_val.indexOf('.') >= 0) {
      const decimal_pos = input_val.indexOf('.');
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos + 1); // Add +1 here

      left_side = this.formatNumber(left_side);
      right_side = this.formatNumber(right_side);

      if (blur === true) {
        while (right_side.length < 2) {
          right_side += '0';
        }
      }

      right_side = right_side.substring(0, 2);
      input_val = left_side + '.' + right_side;
    } else {
      input_val = this.formatNumber(input_val);

      if (blur === true) {
        input_val += '.00';
      }
    }

    this.el.nativeElement.value = input_val;
    const updated_len = input_val.length;
    const new_caret_pos = updated_len - original_len + caret_pos;
    this.el.nativeElement.setSelectionRange(new_caret_pos, new_caret_pos);
  }
}
