import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NetworkWarningComponent } from './core/shared/network-warning/network-warning.component';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, NetworkWarningComponent]
})
export class AppComponent {}
