import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { environment } from 'src/assets/environments/environment.prod';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}

  getQuotation() {}

  getQuotationList(queryParams: any) {
    let params = this.appSettingsService.queryStrFormat(queryParams);

    return this.http.get(`quotation?` + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;

          for (const item of rows) {
            let quotationForm = item?.quotationFileName
              ? environment.mediaUrl + item?.quotationFileName
              : null;

            let payMethod = '';
            if (item?.order?.paymentMethod == 'wire') {
              payMethod = 'ACH / Wire';
            } else if (item?.order?.paymentMethod == 'ach') {
              payMethod = 'ACH / Wire';
            } else if (item?.order?.paymentMethod == 'wire_ach') {
              payMethod = 'ACH / Wire';
            }

            let temp: any = {
              ...item,
              ...item?.userDetails,
              ...item?.companyDetails,
              isAgreementQuo: item.agreementId != null ? true : false,
              agreementDetails: item?.agreementDetails,
              id: parseInt(item.id),
              PONumber: item?.PONumber,
              quotation: 'Yes',
              quotationForm: quotationForm,
              QuotationStatus:item.status,
              programName: item?.order?.programConfigDetails?.name,
              paymentMethod: payMethod,
              userName:
                item?.userDetails?.firstName +
                ' ' +
                item?.userDetails?.lastName,
              CompanyName: item?.companyDetails?.CompanyName,
              createdAt: moment(item?.createdAt).format('YYYY MMMM DD'),
              updatedAt: moment(item?.updatedAt).format('YYYY MMMM DD'),
              companyNumber: item?.companyDetails?.companyNumber,
              DateQuotationRequested: moment(item?.createdAt).format(
                'DD-MMM-YYYY'
              ),
              DateQuotationSent: moment(item?.createdAt).format('YYYY MMMM DD')
            };
            // Company number, date quotation requested, date quotation sent.
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  updateQuotation(id: number, data: any) {
    return this.http.put('quotation/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  getQuotationById(id: number) {
    return this.http.get('quotation/' + id, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
}
