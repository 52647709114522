import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { Roles } from 'src/app/core/module/roles-interface';
import { DataService } from '../../home/program-license-agreement-process/data.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private programBrandAnnualRenewalPer = new BehaviorSubject<any>(null);
  private programBrandAnnualRenewalPerStorageKey = 'fq-program-brand-annual';
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {
    const programBrandAnnualRenewalPerData = localStorage.getItem(
      this.programBrandAnnualRenewalPerStorageKey
    );

    if (programBrandAnnualRenewalPerData) {
      this.programBrandAnnualRenewalPer.next(
        JSON.parse(programBrandAnnualRenewalPerData)
      );
    }
  }

  getRoles(queryParams: any): Observable<any> {
    let params = this.appSettingsService.queryStrFormat(queryParams);

    return this.http.get('menu-role/list?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let temp: Roles = {
              ...item
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }
  private menuRoleCache: any = null;

  createRoles(data: Roles): Observable<any> {
    this.menuRoleCache = null;
    return this.http.post('menu-role/create', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  getByRolesId(id: any): Observable<any> {
    // Check if the data is already cached
    // if (this.menuRoleCache) {
    //   return of(this.menuRoleCache); // Return cached data as an observable
    // }

    return this.http.get('menu-role/' + id).pipe(
      map((resp: any) => {
        let Roles: any;
        if (resp && resp.data) {
          let data = resp.data;
          Roles = data;
        }
        this.menuRoleCache = Roles;
        return Roles;
      })
    );
  }
  getByRolesIdEdit(id: any): Observable<any> {
    return this.http.get('menu-role/' + id).pipe(
      map((resp: any) => {
        let Roles: any;
        if (resp && resp.data) {
          let data = resp.data;
          Roles = data;
        }
        this.menuRoleCache = Roles;
        return Roles;
      })
    );
  }

  updateRoles(id: any, data: Roles): Observable<any> {
    this.menuRoleCache = null;
    return this.http.put('menu-role/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  deleteRoles(id: any): Observable<any> {
    return this.http.delete('menu-role/' + id, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  //handle error messages
  handleError(err: any) {
    this.appSettingsService.handleError(err);
  }

  getAllRoles(params: any): Observable<any> {
    let param = this.appSettingsService.queryStringFormatWithoutOffset(params);
    return this.http.get('menu-role/list?' + param, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          retData.items = resp.data.rows.map((item: any) => ({ ...item }));
          // Assuming resp.data contains the JSON data
          // retData.items = resp.data.rows.map((permission: any) => ({
          //   id: parseInt(permission.id),
          //   name: resp.data.name,
          //   menuID: parseInt(permission?.menuID),
          //   menuActionID: parseInt(permission?.menuActionId),
          //   menuAction: {
          //     id: parseInt(permission.menuAction?.id),
          //     name: permission.menuAction?.name,
          //     isDelete: permission.menuAction?.isDelete
          //   }
          // }));

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }

  /**
   * Checks the company brand renewal permissions for a specific program configuration and company.
   *
   * This method sends a GET request to the server to verify the permissions
   * for brand renewal based on the provided program configuration ID and company ID.
   *
   * @param programConfigId - The ID of the program configuration to check permissions for.
   * @param companyId - The ID of the company to check permissions for.
   *
   * @returns An Observable that emits the permission data returned from the server.
   *          The exact structure of this data depends on the server's response.
   *
   * @example
   * // Usage example
   * this.service.getCheckCompanyBrandRenewalPermissions(123, 456)
   *   .subscribe(
   *     (permissions) => console.log('Permissions:', permissions),
   *     (error) => console.error('Error fetching permissions:', error)
   *   );
   *
   * @remarks
   * - This method uses Angular's HttpClient to make the API request.
   * - The response is processed using RxJS operators to extract the 'data' property.
   * - Error handling should be implemented by the caller of this method.
   * - The server endpoint is expected to be 'permissions/check-permission' with query parameters.
   */
  getCheckCompanyBrandRenewalPermissions(
    programConfigId: number,
    companyId: number
  ): Observable<any> {
    // Check if the data is already cached
    // const cachedData = this.programBrandAnnualRenewalPer.getValue(); // Retrieve the current value of the BehaviorSubject
    // console.log(cachedData);
    // if (cachedData) {
    //   console.log('Cached data available: ', cachedData);
    //   return of(cachedData); // Return cached data as an observable
    // }
    return this.http
      .get(
        `permissions/check-permission?programConfigId=${programConfigId}&companyId=${companyId}`
      )
      .pipe(
        map((resp: any) => {
          this.programBrandAnnualRenewalPer.next(resp.data);
          localStorage.setItem(
            this.programBrandAnnualRenewalPerStorageKey,
            JSON.stringify(resp.data)
          );
          return resp.data;
        })
      );
  }
}
